<template>
<div class="bg-white">
  <a-tabs>
    <a-tab-pane key="1" tab="图片">
      <div class="p-15">
        <imgPage v-model:value="imgObj.search" @search="handleSearchImg"></imgPage>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="音视频">
      <div class="p-15">
        <videoPage v-model:value="videoObj.search" @search="handleSearchVideo"></videoPage>
      </div>
    </a-tab-pane>
    <a-tab-pane key="3" tab="文档">
      <div class="p-15">
        <wordPage v-model:value="wordObj.search" @search="handleSearchWord"></wordPage>
      </div>
    </a-tab-pane>
    <a-tab-pane key="4" tab="PPT">
      <wordPage v-model:value="pptObj.search" @search="handleSearchPpt" placeholder="请输入ppt的名字进行搜索"></wordPage>
    </a-tab-pane>
  </a-tabs>
</div>
</template>

<script>
import imgPage from './components/imgPage.vue'
import wordPage from './components/wordPage.vue'
import videoPage from './components/videoPage.vue'
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  name: 'soursePage',
  components: {
    imgPage,
    wordPage,
    videoPage
  },
  setup () {
    const imgObj = reactive({
      search: ''
    })
    const videoObj = reactive({
      search: ''
    })
    const wordObj = reactive({
      search: ''
    })
    const pptObj = reactive({
      search: ''
    })
    const handleSearchImg = () => {
      console.log(imgObj.search)
    }
    const handleSearchVideo = () => {
      console.log(videoObj.search)
    }
    const handleSearchWord = () => {
      console.log(wordObj.search)
    }
    const handleSearchPpt = () => {
      console.log(pptObj.search)
    }
    return {
      imgObj,
      wordObj,
      videoObj,
      pptObj,
      handleSearchImg,
      handleSearchVideo,
      handleSearchWord,
      handleSearchPpt
    }
  }
})
</script>

<style lang="scss" scoped>
.bg-white{
  background: #fff;
}
</style>
